const NotFound = () => {
  return (
    <div className="content-wrap privacy">
      <h1>404 Page Not Found</h1>
      <h3>Sorry, this URL does not seem to exist.</h3>
    </div>
  );
};

export default NotFound;
